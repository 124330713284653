<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-myclass-notice">
    <lxp-main-header :show-back="true" :show-custom-title="true" :title="menuTitle"/>
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="list-top">
        <div class="top-column ps-6">
          <p class="title text-muted">총 {{paging.totalCount}}건</p>
        </div>
      </div>
      <div class="board-list-container">
        <ul class="board-list">
          <li class="board-list-item" v-for="(item,idx) in bulletins" :key="idx">
            <article class="board-row">
              <router-link :to="{name: 'LearnBulletinView', params: { mode : mode, lrnPostSn: item.lrnPostSn }}" class="board-link"></router-link>
              <div class="board-column column-index"><em class="text text-gold">{{ item.topFixYn === 'Y' ? '필독' : getSequenceNum(idx) }}</em></div>
              <div class="board-column column-file">
                <template v-if="item.fileAtchYn === 'Y'"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></template>
                <template v-else>&nbsp;</template>
              </div>
              <div class="board-column column-title"><span class="title">{{ item.title }}</span>
                <!-- TODO::새로운 공지 아이콘 표시 어떻게 할지? -->
                <i class="icon-new"></i>
              </div>
              <div class="board-column column-date"><span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')  }}</span></div>
              <div class="board-column column-hit"><span class="text">{{ item.inqCnt }} 조회</span></div>
            </article>
          </li>
        </ul>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import CommonPaginationFront from '@/components/CommonPaginationFront';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {computed, ref, watch} from 'vue';
import {getCheckItems, getPaging, initPaging, timestampToDateFormat} from '@/assets/js/util';
import {ACT_GET_BOARD_LIST} from '@/store/modules/board/board';

export default {
  name: 'LearnBulletin',
  components: {LxpMainHeader, CommonPaginationFront},
  setup(){
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const mode = computed(() => route.params.mode);
    const distCrseSn = computed(() => route.query.distCrseSn || 0);

    const menuTitle = computed(() => {
      if (route.params.mode === 'notice') {
        return '공지사항';
      } else { //'lib'
        return '자료실'
      }
    });
    const boardTyCdDcd = computed(() => {
      if (route.params.mode === 'notice') {
        return '2033001';
      } else { //'lib'
        return '2033002'
      }
    });

    // watch(() => route.params.mode, () => {
    //   console.log('route.params.mode : ',route.params.mode);
    //   console.log('mode.value : ',mode.value);
    //   if (mode.value === 'notice') {
    //     boardTyCdDcd.value = '2033001';
    //     menuTitle.value = '공지사항';
    //   } else if (mode.value === 'lib') {
    //     boardTyCdDcd.value = '2033002';
    //     menuTitle.value = '자료실';
    //   }
    // });

    const bulletins = ref([]);

    const paging = ref(initPaging(route));

    const getNotices = async () => {
      await store.dispatch(`board/${ACT_GET_BOARD_LIST}`, {
        boardTyCdDcd: boardTyCdDcd.value,
        distCrseSn: distCrseSn.value,
        // expsrYn:'Y', // TODO:: 파라미터 정의해야됨
        // stt: '00',   // TODO:: 파라미터 정의해야됨
        ...paging.value
      }).then(res => {
        bulletins.value = getCheckItems(res);
        paging.value = getPaging(res);

      }).catch(() => {});
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo, distCrseSn: distCrseSn.value};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      // if (params.testName) query.testName = params.testName;
      router.push({query: query});
    };

    getNotices();

    const getSequenceNum = (idx) => {
      return (paging.value.totalCount - ((paging.value.pageNo - 1) * paging.value.pageSize) - idx);
    }

    watch(() => route.query, () => {
      if (route.name === 'LearnBulletin') {
        if (route.query.pageNo) {
          paging.value.pageNo = route.query.pageNo;
        } else {
          paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
          paging.value.pageSize = route.query.pageSize;
        }

        getNotices();
      }
    });

    return {
      route,
      router,
      store,
      mode,
      menuTitle,
      paging,
      pagingFunc,
      bulletins,
      getSequenceNum,
      timestampToDateFormat
    }
  }
};
</script>